<template>
  <div class="h-full">
    <LoadingPage />
    <Toast />
    <template v-if="currentRetailer">
      <router-view v-show="!isLoading" />
    </template>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import LoadingPage from '../../loading/views/LoadingPage.vue';
import Toast from '../../../components/common/Toast/index.vue';

export default {
  name: 'orders-wrapper',
  components: {
    LoadingPage,
    Toast,
  },
  computed: {
    ...mapState('retailer', ['retailers', 'currentRetailer']),
    ...mapState('loading', ['isLoading']),
  },
  methods: {
    ...mapActions('retailer', ['getRetailers']),
    ...mapActions('loading', ['setIsLoading']),
  },
  async mounted() {
    if (this.retailers.length === 0) {
      this.setIsLoading({ isLoading: true });
      await this.getRetailers();
    }

    const currentRoute = this.$route.name;
    if (currentRoute !== 'orders') {
      await this.$router.replace({ name: 'orders' });
    }
  },
};
</script>
