<!-- eslint-disable max-len -->
<template>
  <div v-if="showToast" class="toast mx-auto w-auto absolute z-30 left-2 top-2 right-2 sm:left-auto" :class="toastType && `toast-${toastType}`">
    <div class="flex-grow">{{ toastMessage }}</div>
    <button
      aria-label="Close"
      class="button-icon button-xs"
      @click="hideToast"
    >
      <XIcon size="20"/>
    </button>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { XIcon } from '@vue-hero-icons/solid';

export default {
  name: 'toast',
  components: {
    XIcon,
  },
  computed: {
    ...mapState('toast', ['showToast', 'toastMessage', 'toastType']),
  },
  methods: {
    ...mapActions('toast', ['hideToast']),
  },
};
</script>
