<!-- eslint-disable max-len -->
<template>
  <div class="hero-pattern min-h-screen flex justify-center" v-if="isLoading">
    <div class="view-children">
      <div class="load-icon">
        <div class="load-icon-arc animate-spin" :class="{ 'bg-white': showIcon}"></div>
        <div class="load-icon-circle">
          <CheckIcon class="text-green-600" size="36" v-if="showIcon === 'success'" />
          <svg v-else class="load-icon-brand" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.7803 15.8525L21.925 24.0002L23.9992 21.9255L15.8537 13.7778L13.7803 15.8525Z" fill="white" />
            <path d="M8.14548 10.2216L10.2189 8.14765L2.07343 0L0 2.07397L8.14548 10.2216Z" fill="white" />
            <path d="M8.14548 13.7778L0 21.9255L2.07343 24.0002L10.2189 15.8525L8.14548 13.7778Z" fill="white" />
            <path d="M15.8544 10.2216L23.9999 2.07397L21.9257 0L13.7803 8.14765L15.8544 10.2216Z" fill="white" />
          </svg>
        </div>
      </div>
      <div class="load-text text-center">{{ loadingMessage || '&nbsp;' }}</div>
    </div>
  </div>
</template>

<script>
import { CheckIcon } from '@vue-hero-icons/outline';
import { mapState } from 'vuex';

export default {
  name: 'loading-page',
  components: {
    CheckIcon,
  },
  computed: {
    ...mapState('loading', ['isLoading', 'showIcon', 'loadingMessage']),
  },
};
</script>
